exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-category-investirane-js": () => import("./../../../src/pages/category/investirane.js" /* webpackChunkName: "component---src-pages-category-investirane-js" */),
  "component---src-pages-category-investirane-novini-js": () => import("./../../../src/pages/category/investirane-novini.js" /* webpackChunkName: "component---src-pages-category-investirane-novini-js" */),
  "component---src-pages-category-investirane-psihologia-js": () => import("./../../../src/pages/category/investirane-psihologia.js" /* webpackChunkName: "component---src-pages-category-investirane-psihologia-js" */),
  "component---src-pages-category-valuation-js": () => import("./../../../src/pages/category/valuation.js" /* webpackChunkName: "component---src-pages-category-valuation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-11-11-2019-investitane-i-spestiavane-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/11.11.2019/investitane-i-spestiavane.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-11-11-2019-investitane-i-spestiavane-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-12-11-2022-beta-investing-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/12.11.2022/beta-investing.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-12-11-2022-beta-investing-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-14-11-2019-1-cenata-na-vremeto-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/14.11.2019_1/cenata-na-vremeto.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-14-11-2019-1-cenata-na-vremeto-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-14-11-2019-moment-za-investirane-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/14.11.2019/moment-za-investirane.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-14-11-2019-moment-za-investirane-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-15-11-2019-psihologia-i-pazar-na-akcii-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/15.11.2019/psihologia-i-pazar-na-akcii.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-15-11-2019-psihologia-i-pazar-na-akcii-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-16-1-2022-1-inflation-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/16.1.2022_1/inflation.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-16-1-2022-1-inflation-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-16-1-2022-inflation-and-assets-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/16.1.2022/inflation_and_assets.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-16-1-2022-inflation-and-assets-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-18-11-2019-efectivnost-na-pazara-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/18.11.2019/efectivnost-na-pazara.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-18-11-2019-efectivnost-na-pazara-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-19-11-2019-1-netna-nastoaiashta-stoinost-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/19.11.2019_1/netna-nastoaiashta-stoinost.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-19-11-2019-1-netna-nastoaiashta-stoinost-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-19-11-2019-risk-i-cena-na-capitala-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/19.11.2019/risk-i-cena-na-capitala.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-19-11-2019-risk-i-cena-na-capitala-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2023-01-sat-jan-28-2023-11-24-45-gmt-0200-eastern-european-standard-time-диверсификация-риск-и-емоции-md": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/2023_01_sat-jan-28-2023-11-24-45-gmt-0200-eastern-european-standard-time/диверсификация-риск-и-емоции.md" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2023-01-sat-jan-28-2023-11-24-45-gmt-0200-eastern-european-standard-time-диверсификация-риск-и-емоции-md" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2023-01-sun-jan-22-2023-17-05-02-gmt-0200-eastern-european-standard-time-title-md": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/2023_01_sun-jan-22-2023-17-05-02-gmt-0200-eastern-european-standard-time/title.md" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2023-01-sun-jan-22-2023-17-05-02-gmt-0200-eastern-european-standard-time-title-md" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2023-05-tue-may-02-2023-15-25-58-gmt-0300-eastern-european-summer-time-страх-от-загуба-md": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/2023_05_tue-may-02-2023-15-25-58-gmt-0300-eastern-european-summer-time/страх-от-загуба.md" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2023-05-tue-may-02-2023-15-25-58-gmt-0300-eastern-european-summer-time-страх-от-загуба-md" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2024-12-sun-dec-08-2024-14-43-05-gmt-0200-eastern-european-standard-time-балонът-в-цените-на-недвижимите-имоти-md": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/2024_12_sun-dec-08-2024-14-43-05-gmt-0200-eastern-european-standard-time/балонът-в-цените-на-недвижимите-имоти.md" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2024-12-sun-dec-08-2024-14-43-05-gmt-0200-eastern-european-standard-time-балонът-в-цените-на-недвижимите-имоти-md" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2025-04-sun-apr-06-2025-13-08-28-gmt-0300-eastern-european-summer-time-как-да-си-осигурим-достойна-пенсия-md": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/2025_04_sun-apr-06-2025-13-08-28-gmt-0300-eastern-european-summer-time/как-да-си-осигурим-достойна-пенсия.md" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-2025-04-sun-apr-06-2025-13-08-28-gmt-0300-eastern-european-summer-time-как-да-си-осигурим-достойна-пенсия-md" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-21-05-2022-pazarite-padat-kakvo-da-pravim-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/21.05.2022/pazarite-padat-kakvo-da-pravim.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-21-05-2022-pazarite-padat-kakvo-da-pravim-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-21-11-2019-dividend-valuation-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/21.11.2019/dividend-valuation.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-21-11-2019-dividend-valuation-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-25-11-2019-ocenka-na-stabilna-compania-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/25.11.2019/ocenka-na-stabilna-compania.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-25-11-2019-ocenka-na-stabilna-compania-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-26-11-2019-zashto-da-investirame-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/26.11.2019/zashto-da-investirame.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-26-11-2019-zashto-da-investirame-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-27-11-2019-etf-i-mutual-funds-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/27.11.2019/etf-i-mutual-funds.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-27-11-2019-etf-i-mutual-funds-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-28-02-2021-growth-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/28.02.2021/growth.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-28-02-2021-growth-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-28-11-2019-povedencheski-finansi-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/28.11.2019/povedencheski-finansi.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-28-11-2019-povedencheski-finansi-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-30-01-2021-1-kasi-prodajbi-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/30.01.2021_1/kasi-prodajbi.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-30-01-2021-1-kasi-prodajbi-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-30-01-2021-2-hedgefund-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/30.01.2021_2/hedgefund.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-30-01-2021-2-hedgefund-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-30-01-2021-3-gamestop-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/30.01.2021_3/gamestop.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-30-01-2021-3-gamestop-mdx" */),
  "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-30-01-2022-portfolio-return-expectation-mdx": () => import("./../../../src/temp/blog-post.js?__contentFilePath=/opt/build/repo/src/posts/30.01.2022/portfolio_return_expectation.mdx" /* webpackChunkName: "component---src-temp-blog-post-js-content-file-path-opt-build-repo-src-posts-30-01-2022-portfolio-return-expectation-mdx" */)
}

